import React from 'react';
import { WrapRootElementBrowserArgs } from 'gatsby';

import './src/styles/reset.scss';
import './src/styles/fonts.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { IntroContextProvider } from './src/contexts/intro-context';

export const wrapRootElement = ({ element }: WrapRootElementBrowserArgs) => {
    return <IntroContextProvider>{element}</IntroContextProvider>;
};
