const POST_PAGE_COUNT = 8;
const POST_INITIAL_CURRENT_PAGE = 1;
const INTRO_SESSION_KEY = 'introShowed';
// const INTRO_SHOWED_SESSION_VALUE = 'showed';
const INTRO_SHOWED_SESSION_VALUE = '';
const WORK_PAGE_COUNT = 4;
const WORK_INITIAL_CURRENT_PAGE = 1;
const COOKIES_ACCEPT_KEY = 'cookiesAccept';
const COOKIES_ACCEPT_VALUE = 'accepted';

module.exports = {
    POST_PAGE_COUNT,
    POST_INITIAL_CURRENT_PAGE,
    INTRO_SESSION_KEY,
    INTRO_SHOWED_SESSION_VALUE,
    WORK_PAGE_COUNT,
    WORK_INITIAL_CURRENT_PAGE,
    COOKIES_ACCEPT_KEY,
    COOKIES_ACCEPT_VALUE,
};
